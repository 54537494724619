<template>
    <!--   保租房发布弹框       -->
    <r-e-dialog title="发布" :visible="dialogVisible" show-footer @click-submit="handleSubmitPublish"
                @click-cancel="handleCancel" @close="handleCancel" width="780px">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="地址" prop="name">
                <el-input disabled v-model="formPublish.name"/>
            </el-form-item>
            <el-form-item label="单价" prop="monthPriceAmount">
                <el-input v-model="formPublish.monthPriceAmount">
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>
          <el-form-item label="服务费" prop="tipAmount">
            <el-input v-model="formPublish.tipAmount">
              <template slot="append">元/月</template>
            </el-input>
          </el-form-item>
            <el-form-item label="面积" prop="insideArea">
                <el-input type="number" disabled v-model="formPublish.insideArea">
                    <template slot="append">㎡</template>
                </el-input>
            </el-form-item>
            <div class="flex">
                <el-form-item label="半年租金折扣" prop="sixMonthDiscount">
<!--                  :disabled="disabled"-->
                    <el-input  type="number" v-model="formPublish.sixMonthDiscount">
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="半年租金">
                    <el-input disabled type="number" v-model="amountData.halfRentAmount">
                        <template slot="append">元/月</template>
                    </el-input>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="半年押金">
                    <el-input disabled type="number" v-model="amountData.halfBoneAmount">
                        <template slot="append">元/月</template>
                    </el-input>
                </el-form-item>
              <el-form-item label="年押金">
                <el-input disabled type="number" v-model="amountData.yearBoneAmount">
                  <template slot="append">元/月</template>
                </el-input>
              </el-form-item>
<!--                <el-form-item label="半年定金">-->
<!--                    <el-input disabled type="number" v-model="amountData.halfDing">-->
<!--                        <template slot="append">元/月</template>-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
            </div>
            <div class="flex">
                <el-form-item  label="年租金折扣" prop="yearDiscount">
                    <el-input  type="number" v-model="formPublish.yearDiscount">
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="年租金">
                    <el-input disabled type="number" v-model="amountData.yearRentAmount">
                        <template slot="append">元/月</template>
                    </el-input>
                </el-form-item>
            </div>
            <div class="flex">

<!--                <el-form-item label="年定金">-->
<!--                    <el-input disabled type="number" v-model="amountData.yearDing">-->
<!--                        <template slot="append">元/月</template>-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
            </div>
            <div class="flex">
                <el-form-item label="保租房项目" style="width: 100%;">
                    <el-select v-model="projectId" placeholder="请选择" filterable style="width: 100%;">
                        <el-option v-for="item in projectList" :key="item.id" :label="item.project_name" :value="item.id"/>
                    </el-select>
                </el-form-item>
            </div>
          <div class="flex">
            <el-form-item label="住建平台" style="width: 100%;" prop="verificationCode">
              <el-radio v-model="formPublish.verificationCode" :label="true">推送</el-radio>
              <el-radio v-model="formPublish.verificationCode" :label="false">不推送</el-radio>
              <div style="color: red">当选择推送至住建平台，仅具备有住建房源核验码才可推送</div>
            </el-form-item>
          </div>
            <div>
                <el-form-item label="">
                    <el-button type="primary" @click="getAmountData">计算金额</el-button>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
    import {MessageSuccess, MessageWarning} from "@custom/message";
    import {
      apartmentDiscountAmountInfo,
      bzfGetAmount,
      getBzfProject,
      bzfProjectPut,
      getHouseList
    } from "@/api/house-resource";
    import {publishApartment} from "@/api/publish";
    export default {
        name: "layer-bzf-publish",
        data() {
          //自定义区间值校验
          let validatePass = (rule, value, callback) => {
            value = Number(value);
            if (value < this.remarkmin  || value > this.remarkmax ) {
              callback(new Error(`请输入${this.remarkmin} ~ ${this.remarkmax}之间的数值`));
            } else if (value % 1 !== 0) {
              callback(new Error('请输入整数值'));
            } else {
              callback();
            }
          };
            return {
                 remarkmin:'',
                 remarkmax:'',
                 disabled:true,
                dialogVisible: false,
                formPublish: {
                    name:null,
                    apartmentUuid: null,
                    insideArea: null,
                    outsideArea: null,
                    monthPriceAmount: null,
                    yearDiscount: 100,
                    sixMonthDiscount: 100,
                    tipAmount:null,
                    verificationCode:null
                },
                rules: {
                    monthPriceAmount: [{required: true, message: "请输入单价", trigger: "blur"},],
                  tipAmount: [{required: true, message: "请输入单价", trigger: "blur"},],
                    sixMonthDiscount: [{required: true, message: "请输入半年折扣", trigger: "blur"},   {validator: validatePass, trigger: 'blur'}],
                    yearDiscount: [{required: true, message: "请输入年折扣", trigger: "blur"},   {validator: validatePass, trigger: 'blur'}],
                    outsideArea: [{required: true, message: "请输入建筑面积", trigger: "blur"},],
                    insideArea: [{required: true, message: "请输入使用面积", trigger: "blur"},],
                  verificationCode: [
                    { required: true, message: '请选择是否推送', trigger: 'change' }
                  ],
                },
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
                amountData: {
                    halfBoneAmount: null,
                    halfDing: null,
                    halfRentAmount: null,
                    yearBoneAmount: null,
                    yearDing: null,
                    yearRentAmount: null,
                },
                projectId: null,
                projectList: []
            };
        },
        async created() {
        },
        mounted() {
        },
        methods: {
            async openDialog(data) {
                console.log(data)
                let that = this;
                let {name, uuid,mainUuid} = data;
                if(mainUuid === null) this.disabled = false
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                let {info} = await apartmentDiscountAmountInfo({uuid});
                let res = await bzfGetAmount({uuid, monthPriceAmount: null, yearDiscount: null, sixMonthDiscount: null});
              // console.log(info.tipAmount,'999999999999999999999999999999999999999')
                let {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount,} = res.info;
                halfBoneAmount = ParseFloatFormat(halfBoneAmount);
                halfDing = ParseFloatFormat(halfDing);
                halfRentAmount = ParseFloatFormat(halfRentAmount);
                yearBoneAmount = ParseFloatFormat(yearBoneAmount);
                yearDing = ParseFloatFormat(yearDing);
                yearRentAmount = ParseFloatFormat(yearRentAmount);
                let {sixMonthDiscount,yearDiscount,monthPriceAmount, outsideArea, insideArea,tipAmount} = info;
                monthPriceAmount = ParseFloatFormat(monthPriceAmount);
                this.formPublish = {name, apartmentUuid: uuid, insideArea, outsideArea, monthPriceAmount, yearDiscount, sixMonthDiscount,tipAmount:tipAmount/100};
                that.amountData = {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount};
              //   2024-4-11-陈守亮-折扣规则动态修改
              let reslist  = await getHouseList({apartmentUuid: uuid})
              console.log(reslist)
              that.remarkmin = reslist.list[0].value
              that.remarkmax = reslist.list[1].value
                const {msg} = await getBzfProject();
                const {result} =  JSON.parse(msg)
                // console.log(result);
                that.projectList = result;
                loading.close();
                this.formPublish.yearDiscount = 100
                this.formPublish.sixMonthDiscount = 100

                this.dialogVisible = true;
            },
            handleSubmitPublish() {
                let that = this;
                // 表单提交
                this.$refs.formPublish.validate((valid) => {
                    if (valid) {
                      const loading = that.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                        let {apartmentUuid, insideArea, outsideArea, monthPriceAmount, yearDiscount, sixMonthDiscount,verificationCode} = that.formPublish;
                        // alert(verificationCode)
                      // if(verificationCode) return
                          // return
                        monthPriceAmount = ParseIntFormat(monthPriceAmount);
                      let mytipAmount = ParseIntFormat(that.formPublish.tipAmount);
                        let params = {apartmentUuid, insideArea, outsideArea, monthPriceAmount, yearDiscount, sixMonthDiscount ,tipAmount:mytipAmount,verificationCode};
                        publishApartment(params).then(res => {
                          loading.close();
                            MessageSuccess(`已发送`);
                            that.handleCancel("update");
                        }).catch(err => {
                          loading.close();
                        })
                    } else {
                        MessageWarning("请填写必填值");
                        return false;
                    }
                });
            },
            handleCancel(closeType = "cancel") {
                this.$refs["formPublish"].resetFields();
                // 取消、关闭弹窗
                this.dialogVisible = false;
                // const currentPage = this.$refs.apartmentTableRef.currentPage;
                // this.$emit("handleSearch");
                this.$parent.$refs.apartmentTableRef.getTableData(false);
            },
            async getAmountData(){
                let that = this;
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                let {apartmentUuid: uuid,monthPriceAmount, yearDiscount, sixMonthDiscount} = that.formPublish;
                monthPriceAmount = ParseIntFormat(monthPriceAmount);
                let res = await bzfGetAmount({uuid, monthPriceAmount, yearDiscount, sixMonthDiscount});
                let {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount} = res.info;
                halfBoneAmount = ParseFloatFormat(halfBoneAmount);
                halfDing = ParseFloatFormat(halfDing);
                halfRentAmount = ParseFloatFormat(halfRentAmount);
                yearBoneAmount = ParseFloatFormat(yearBoneAmount);
                yearDing = ParseFloatFormat(yearDing);
                yearRentAmount = ParseFloatFormat(yearRentAmount);
                that.amountData = {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount};
                loading.close();
            }
        },

        watch:{
            projectId(value){
                if(value){
                    let {apartmentUuid: uuid} = this.formPublish;
                    bzfProjectPut({uuid,projectId:value});
                }
            }
        }
    }
</script>

<style scoped>

</style>
